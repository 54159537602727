import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "./SliderComp.css"

export default function Reviews() {

  return (
    <>
        <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper"
            autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
        >
            <SwiperSlide>
                <div className=" w-[80%] mx-auto flex gap-[40px] flex-wrap xl:flex-nowrap flex-col-reverse xl:flex-row">
                    <div className=" w-[calc(100%)]  xl:w-[calc(70%-20px)] bg-[#191E23] pt-[50px] pb-[40px] px-[50px] flex-shrink-0 rounded-[16px]">
                        <div className=" h-6 justify-start items-start inline-flex mb-[17px]">
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                        </div>
                        <div>
                            <p className="text-stone-300 text-[28px] font-semibold mb-[21px] leading-[28px]">Easy to Use</p>
                            <p className=" text-neutral-400 text-[18px] font-normal leading-snug mb-[12px]">"I totally enjoyed the service of enaira by Bullnet."</p>
                            <div className=" h-[90px] justify-start items-center gap-3 inline-flex">
                                <img className="w-[90px] h-[90px] rounded-full object-cover" src="./assets/images/review-img1.jpeg" alt=""/>
                                <p className="text-stone-300 text-[22px] font-semibold capitalize leading-7">Blessing</p>
                            </div>
                        </div>
                    </div>
                    <div className=" w-[calc(100%)]  xl:w-[calc(30%-20px)] ">
                        <img className="w-full h-full object-cover relative rounded-md" src="./assets/images/review-img1.jpeg" alt=""/>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className=" w-[80%] mx-auto flex gap-[40px] flex-wrap xl:flex-nowrap flex-col-reverse xl:flex-row">
                    <div className=" w-[calc(100%)]  xl:w-[calc(70%-20px)] bg-[#191E23] pt-[50px] pb-[40px] px-[50px] flex-shrink-0 rounded-[16px]">
                        <div className=" h-6 justify-start items-start inline-flex mb-[17px]">
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>
                            <img src='./assets/images/review-star.svg' className="w-6 h-6 relative" alt=''/>

                        </div>
                        <div>
                            <p className="text-stone-300 text-[28px] font-semibold mb-[21px] leading-[28px]">eNaira is really a great platform</p>
                            <p className=" text-neutral-400 text-[18px] font-normal leading-snug mb-[12px]">"Its a great easy to use app . I definitely recommend."</p>
                            <div className=" h-[90px] justify-start items-center gap-3 inline-flex">
                                <img className="w-[90px] h-[90px] rounded-full object-cover" src="./assets/images/review-img2.jpeg" alt=""/>
                                <p className="text-stone-300 text-[22px] font-semibold capitalize leading-7">Gerard Albright</p>
                            </div>
                        </div>
                    </div>
                    <div className=" w-[calc(100%)]  xl:w-[calc(30%-20px)] ">
                        <img className="w-full h-full object-cover relative rounded-md" src="./assets/images/review-img2.jpeg" alt=""/>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </>
  ) 
}
