import React,{useState,useEffect,useCallback} from 'react'
import SliderComp from './Components/SliderComp'
import Testimonials from './Components/Testimonials'
import Reviews from './Components/Reviews'
import SubscibeModal from './Components/SubscibeModal'
import {Toaster,toast} from 'react-hot-toast'
import axios from 'axios'



export default function Home() {
    const [faqs,setFaqs] =  useState({1:false,2:false,3:false,4:false,5:false,6:false,7:false})
    const [showNav, setNav] = useState(false)
    const [showSubscribe,setShowSubscribe] = useState("False")
    const [formData, setFormData] = useState({
        'name':'',
        'email':'',
        'phone':'',
        'loading': false
    })

    const subscribe = async (event) => {
        event.preventDefault();
        
        setFormData({...formData, loading:true});

        const dataCenter = 'us21';
        const audienceId = 'eeb5d2586c';
        const apiKey = 'd18af96417f51b5fe43b4ec8d3fdcfb7-us21';
        const url = `https://${dataCenter}.api.mailchimp.com/3.0/lists/${audienceId}/members`;

        // Build user details object to send
        const userDetails = {
            email_address: formData.email,            
            merge_fields: {
                FNAME: formData.name.split(" ")[0],
                LNAME: formData.name.split(" ")[1],
                FLNAME: formData.name,
                PHONE: formData.phone,  
            },
            status: 'subscribed'
        };
  

        axios.post(url, userDetails, {
            auth: {
              username: 'newsletter',
              password: apiKey
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(response => {
              toast.success('Request Successful.', {
                position: "top-center"
              })
              setFormData({
                    'name':'',
                    'email':'',
                    'phone':'',
                    'loading': false
                })
              setShowSubscribe(null)

            })
            .catch(error => {
              toast.error(`An Error occured - ${ error.response?.data.title}`, {
                position: "top-center"
              })
              setFormData({...formData, loading:false});
            });
    
    }

    const handleScroll = (e) => {
        let offset = window.pageYOffset
        if(offset > 1500 && offset < 2000){
            if(showSubscribe === 'False'){
                setShowSubscribe("Show")
                window.removeEventListener("scroll", handleScroll,true);
            }
        }
    }

    const cachedHandleScroll = useCallback(handleScroll,[handleScroll,showSubscribe,])

    useEffect(()=>{
        window.addEventListener('scroll', cachedHandleScroll,true)
    },[cachedHandleScroll])

  return (
    <section className='relative'>
        <SubscibeModal formData={formData} setFormData={setFormData} subscribe={subscribe} showSubscribe={showSubscribe} setShowSubscribe={setShowSubscribe}></SubscibeModal>
        <div id='home' className="w-full h-auto px-[12px] md:pl-[71px] md:pr-[31px] pt-[17px] pb-4 bg-black sm:flex sm:justify-between gap-2">
            <p className="text-white text-[13px] font-medium">eNaira eNhanced Services Powered by BULLNET</p>
            <p className="text-white text-[13px] font-medium">Send Money from Cards (GBP <img alt='' width={20} className='inline mt-[-2px]' src='./assets/images/united-kingdom.png' /> and USD <img alt='' width={20} className='inline mt-[-2px]' src='./assets/images/united-states.png' />) to all Accounts (NGN <img alt='' width={20} className='inline mt-[-2px]' src='./assets/images/nigeria.png' />)</p>
        </div>
        <nav className="w-full h-20 relative bg-white flex items-center justify-between custom-container">
            <img alt='' className="w-[132px] h-[59px] " src="./assets/images/Nav-logo.png" />
            <ul className="justify-start items-center gap-6  hidden xl:inline-flex">
                <li className="py-2 group">
                    <a href='#home' className="text-gray-700 text-[14px] font-medium">Home </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

                <li className="py-2 group">
                    <a href='#about' className="text-gray-700 text-[14px] font-medium">About eNaira </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>
                <li className="py-2 group">
                    <a href='#benefits' className="text-gray-700 text-[14px] font-medium">Benefits of eNaira </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

                <li className="py-2 group">
                    <a href='#get-wallet' className="text-gray-700 text-[14px] font-medium">Get eNaira Wallet </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

                <li className="py-2 group">
                    <a href='#testimonials' className="text-gray-700 text-[14px] font-medium">Testimonials </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

                <li className="py-2 group">
                    <a href='#become-agent' className="text-gray-700 text-[14px] font-medium">Become an Agent </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

                <li className="py-2 group">
                    <a href='/' className="text-gray-700 text-[14px] font-medium">Blog & News </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

                <li className="py-2 group">
                    <a href='#faqs' className="text-gray-700 text-[14px] font-medium">Help </a>
                    <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                </li>

            </ul>
            <div className=" justify-start items-start gap-3 hidden xl:inline-flex">
                <a href="https://enaira-frontend.vercel.app/auth/register" className="pl-[18px] pr-[17px] py-3 bg-[#b8860b] rounded justify-center items-center flex text-white text-[14px] font-semibold gap-2.5 flex">
                    Register Now
                </a>
                <a href="https://enaira-frontend.vercel.app/auth/login" className="pl-[18px] pr-[17px] py-3 bg-[#16780D] rounded justify-center items-center flex text-white text-[14px] font-semibold">
                    Send Money Now
                </a>
            </div>
            <div class="-mr-2 flex items-center xl:hidden">
                <button onClick={() => setNav(!showNav)} type="button" class="bg-[#16780D] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                </button>
            </div>

            {showNav && <div class="absolute top-[calc(100%+20px)] w-full right-0 left-0 block xl:hidden z-50 bg-white">
              <div onClick={() => setNav(!showNav)} class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <ul className="gap-6">
                    <li className="py-2 group">
                        <a href='#home' className="text-gray-700 text-[14px] font-medium">Home </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                    <li className="py-2 group">
                        <a href='#about' className="text-gray-700 text-[14px] font-medium">About eNaira </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>
                    <li className="py-2 group">
                        <a href='#benefits' className="text-gray-700 text-[14px] font-medium">Benefits of eNaira </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                    <li className="py-2 group">
                        <a href='#get-wallet' className="text-gray-700 text-[14px] font-medium">Get eNaira Wallet </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                    <li className="py-2 group">
                        <a href='#testimonials' className="text-gray-700 text-[14px] font-medium">Testimonials </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                    <li className="py-2 group">
                        <a href='#become-agent' className="text-gray-700 text-[14px] font-medium">Become an Agent </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                    <li className="py-2 group">
                        <a href='/' className="text-gray-700 text-[14px] font-medium">Blog & News </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                    <li className="py-2 group">
                        <a href='#faqs' className="text-gray-700 text-[14px] font-medium">Help </a>
                        <div className="w-[37px] h-[0px] border border-[#16780D] invisible group-hover:visible"></div>
                    </li>

                </ul>
                <div className=" justify-start items-start gap-3 flex">
                    <a href="https://enaira-frontend.vercel.app/auth/register" className=" p-2.5 rounded border text-[#16780D] text-[14px] font-semibold border-[#16780D] justify-center items-center gap-2.5 flex">
                        Register Now
                    </a>
                    <a href="https://enaira-frontend.vercel.app/auth/login" className="pl-[18px] pr-[17px] py-3 bg-[#16780D] rounded justify-center items-center flex text-white text-[14px] font-semibold">
                        Send Money Now
                    </a>
                </div>
              </div>
            </div>}

        </nav>
        <SliderComp></SliderComp>
        <section id='about' className="bg-[#3F4951CC] py-[27px]">
            <div className=" custom-container">
                <div className=" flex justify-between flex-wrap gap-4">
                    <div className="w-max h-[73px] justify-start items-center gap-2.5 inline-flex">
                        <div className="justify-start items-start gap-2 flex">
                            <img className="w-14 h-14" src="./assets/images/gridicons_multiple-users.svg" alt='' />
                        </div>
                        <div className="">
                            <p className="text-stone-300 text-[32px] font-semibold uppercase">100k+</p>
                            <p className="text-stone-300 text-[16px] font-normal">No of Use</p>
                        </div>
                    </div>

                    <div className="w-max h-[73px] justify-start items-center gap-2.5 inline-flex">
                        <div className="justify-start items-start gap-2 flex">
                            <img className="w-14 h-14" src="./assets/images/moneys.svg" alt=''/>
                        </div>
                        <div className="">
                            <p className="text-stone-300 text-[32px] font-semibold uppercase">1000+</p>
                            <p className="text-stone-300 text-[16px] font-normal">Value of Vouchers Sold</p>
                        </div>
                    </div>

                    <div className="w-max h-[73px] justify-start items-center gap-2.5 inline-flex">
                        <div className="justify-start items-start gap-2 flex">
                            <img className="w-14 h-14" src="./assets/images/user.svg" alt=''/>
                        </div>
                        <div className="">
                            <p className="text-stone-300 text-[32px] font-semibold uppercase">280</p>
                            <p className="text-stone-300 text-[16px] font-normal">No of Agents Onboarded</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        <section  className="pt-[52px] pb-[141px]">
            <div className=" custom-container">
                <div className=" mb-[69px]">
                    <h1 className="text-gray-700 text-[36px] font-normal leading-[55px]">About eNaira eNhanced Services</h1>
                    <div className="w-14 h-[3px] bg-black mb-[12px]"></div>
                    <div className=" text-zinc-600 text-[16px] font-normal">eNaira is an electronic version of the local paper naira currency, equal in value and issued by the Central Bank Nigeria. It functions as a safe and efficient alternative means of payment.
                        Organizations and individuals are seeking fast, safe, easy, and cheap means of payment. eNaira was minted to meet these pressing needs.</div>
                    <div className=" text-zinc-600 text-[16px] font-normal">eNaira eNhanced Services is a layer of services provided by BULLNET (An eNaira Partner Agent & Technology Partner) to extend the eNaira to users and agents with little or no access top adequate financial or digital services and infrastructure within Nigeria and in the diaspora.</div>
                </div>
                
                <div className=" mb-[61px] sm:flex sm:justify-between">
                    <div>
                        <h1 className="text-gray-700 text-[36px] font-normal leading-[55px]">Key Features</h1>
                        <div className="w-14 h-[3px] bg-black "></div>
                    </div>
                    <div className=" mt-[20px] sm:mt-0">
                        <button className=" h-[43px] px-3 py-[9px] border border-neutral-400 text-gray-700 text-[18px] font-normal">
                            More Features
                        </button>
                    </div>
                </div>
                <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-[53px] gap-x-[80px]">
                    <div className="px-[25.94px] py-[51.88px] hover:bg-[#16780D] hover:border-0 border border-stone-300 bg-white rounded-[51.88px] group ">
                        <div className="w-[59px] h-[59px] group-hover:bg-white group-hover:opacity-100 bg-[#16780D] bg-opacity-5 rounded-full flex items-center justify-center mb-[25px]">
                            <img src="./assets/images/moneys2.svg" alt="" />
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[18.5277099609375px] font-semibold">Fast Diaspora Micro-Remittances</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[13.895781517028809px] text-gray-700 font-light mb-[24px]">eNaira Diaspora Remittance extends the eNaira to Nigerians in the diaspora to be able to fund eNaira Wallet & Bank Account with foreign currencies and to onboard new users as well as sell vouchers as agents to customers.</p>
                        <a href="https://enaira-frontend.vercel.app/auth/login" className="text-[#16780D] group-hover:text-white text-[13.895781517028809px] font-light underline text-right">Click here</a>
                    </div>
                    <div className="px-[25.94px] py-[51.88px] hover:bg-[#16780D] hover:border-0 border border-stone-300 bg-white rounded-[51.88px] group ">
                        <div className="w-[59px] h-[59px] group-hover:bg-white group-hover:opacity-100 bg-[#16780D] bg-opacity-5 rounded-full flex items-center justify-center mb-[25px]">
                             <img src="./assets/images/global.svg" alt="" />
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[18.5277099609375px] font-semibold">Fast Transfer to Bank</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[13.895781517028809px] text-gray-700 font-light mb-[24px]">Our direct payment gateway infrastructure between eNaira and the NIP Platforms ensure instant settlements in Bank Accounts.</p>
                        <a href="https://enaira-frontend.vercel.app/auth/login" className="text-[#16780D] group-hover:text-white text-[13.895781517028809px] font-light underline text-right">Click here</a>
                    </div>
                    
                    <div className="px-[25.94px] py-[51.88px] hover:bg-[#16780D] hover:border-0 border border-stone-300 bg-white rounded-[51.88px] group ">
                        <div className="w-[59px] h-[59px] group-hover:bg-white group-hover:opacity-100 bg-[#16780D] bg-opacity-5 rounded-full flex items-center justify-center mb-[25px]">
                            <img src="./assets/images/call-calling.svg" alt="" />
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[18.5277099609375px] font-semibold">24/7 Support</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[13.895781517028809px] text-gray-700 font-light mb-[24px]">We provide round-the-clock support with customer care call lines (07004004004) and technical support for our customers and agents. Our Call Centre Respondents can process payments of voucher purchases directly over the phone.</p>
                        <p className="text-[#16780D] group-hover:text-white text-[13.895781517028809px] font-light underline text-right">Click here</p>
                    </div>

                    <div className="px-[25.94px] py-[51.88px] hover:bg-[#16780D] hover:border-0 border border-stone-300 bg-white rounded-[51.88px] group ">
                        <div className="w-[59px] h-[59px] group-hover:bg-white group-hover:opacity-100 bg-[#16780D] bg-opacity-5 rounded-full flex items-center justify-center mb-[25px]">
                             <img src="./assets/images/bank.svg" alt="" />
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[18.5277099609375px] font-semibold">Low Cost</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[13.895781517028809px] text-gray-700 font-light mb-[24px]">Our Platform is designed and built to keep running costs very low thereby transferring little or no charges to the users.</p>
                        <p className="text-[#16780D] group-hover:text-white text-[13.895781517028809px] font-light underline text-right">Click here</p>
                    </div>
                    <div className="px-[25.94px] py-[51.88px] hover:bg-[#16780D] hover:border-0 border border-stone-300 bg-white rounded-[51.88px] group ">
                        <div className="w-[59px] h-[59px] group-hover:bg-white group-hover:opacity-100 bg-[#16780D] bg-opacity-5 rounded-full flex items-center justify-center mb-[25px]">
                            <img src="./assets/images/Group1.svg" alt="" />
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[18.5277099609375px] font-semibold">Extension Workers</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[13.895781517028809px] text-gray-700 font-light mb-[24px]">eNaira eNhanced Services provide the opportunity for the teeming youths to be productive by becoming eNaira eXtension Workers to promote the extension of eNaira usage to remote areas of Nigeria and earn commissions and fees in the process.</p>
                        <p className="text-[#16780D] group-hover:text-white text-[13.895781517028809px] font-light underline text-right">Click here</p>
                    </div>
                    <div className="px-[25.94px] py-[51.88px] hover:bg-[#16780D] hover:border-0 border border-stone-300 bg-white rounded-[51.88px] group ">
                        <div className="w-[59px] h-[59px] group-hover:bg-white group-hover:opacity-100 bg-[#16780D] bg-opacity-5 rounded-full flex items-center justify-center mb-[25px]">
                            <img src="./assets/images/mdi_phone-dial.svg" alt="" />
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[18.5277099609375px] font-semibold">Ussd Channel</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[13.895781517028809px] text-gray-700 font-light mb-[24px]">Our eNaira USSD Substring (*997*50#) remains the only direct connection on the eNaira USSD Channel to refer, fund, cash out and register agents for the eNaira Wallet.</p>
                        <p className="text-[#16780D] group-hover:text-white text-[13.895781517028809px] font-light underline text-right">Click here</p>
                    </div>
                    
                </div>
            </div> 
        </section>

        <section id='benefits' className=" bg-[#16780D] bg-opacity-5 pt-[40px] pb-[118px]">
            <div className="custom-container ">
                <div className=" text-center mb-[20px]">
                    <h1 className="text-gray-700 text-[36px] font-bold">Why Use eNaira eNhanced Services</h1>
                    <div className="w-14 h-[3px] bg-black mx-auto mb-[24px]"></div>
                    <div className="sm:w-[382px] text-center text-zinc-600 text-[16px] font-normal mx-auto">All the amazing benefits we offer our customers</div>
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[40px]">
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">1</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">Quick & Easy Setup: </h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">Onboarding and registration via USSD and Web made easy by simple input of NIN, BVN or Referral Codes.</p>
                    </div>
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">2</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">Fund Wallets with Vouchers</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">eNaira customers that do not have a bank account or smart phone can fund their wallets with our Patented Voucher PIN Code Generating & Loading System.</p>
                    </div>
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">3</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">Hack-Proof</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">Our Platform uses the formidable AWS loud Security Architecture and a resilient .NET Programming Language with a VPN connection on a USSD Channel making it highly secured.</p>
                    </div>
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">4</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">No Downtime</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">Our Platform, using AWS Cloud Technology ensures 99.99% service availability and reliability.</p>
                    </div>

                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">5</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">No Network Issues</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">The ability of our USSD Channel to carry out all our services afford our customers no network issues during operations.</p>
                    </div>
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">6</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">No Queues or Human Interactions</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">Using our services end-to-end ensures that our customers do not have to visit banks or banking agents.</p>
                    </div>
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">7</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">Referrals & Reward</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">Our Platform incorporates eNaira referral codes and our unique reward multi-level system that motivates users and agents to market the eNaira and earn extra commission.</p>
                    </div>
                    <div className="px-7 py-[77px] hover:bg-[#16780D] bg-white rounded-lg shadow group ">
                        <div className="w-16 h-16 group-hover:bg-white bg-gray-700 rounded-full flex items-center justify-center mb-[40px]">
                            <p className="group-hover:text-[#16780D] text-white text-[32px] font-medium">8</p>
                        </div>
                        <h3 className=" group-hover:text-white text-gray-700 text-[20px] font-semibold">Airtime Mirroring</h3>
                        <div className="w-[40px] h-[3px] group-hover:bg-white text-gray-700 mb-[16px]"></div>
                        <p className=" group-hover:text-white text-[15px] text-gray-700 font-light">Our Platform incorporated a unique and patented Telco airtime PIN code mirroring system to ensure that our vouchers are available in every nook and cranny of Nigeria.</p>
                    </div>
                    
                </div>
            </div>
            
        </section>

        <section id='become-agent' className=" bg-white pt-[40px] pb-[65px]">
            <div className=" custom-container mx-auto ">
                <div className=" text-center mb-[90px]">
                    <h1 className="text-gray-700 text-[36px] font-bold">Become an Agent (eNaira eXtension Worker)” </h1>
                    <div className="w-14 h-[3px] bg-black mx-auto"></div>
                </div>
                <div className="grid lg:grid-cols-2 grid-cols-1 mg:gap-[80px] gap-[40px]">
                    <div className=" w-full xl:h-[515px]">
                        <img src="./assets/images/Frame4743.png" alt="" />
                    </div>
                    <div className=" flex flex-col justify-between">
                        <div>
                            <h1 className="mb-[27px] text-gray-700 text-[24px] font-medium">Make extra cash by becoming an eNaira agent</h1>
                            <p className="mb-[27px] h-[22px] text-zinc-600 text-[18px] font-normal">As an eNaira agent, you will;</p>

                            <ul>
                                <li className="justify-start items-start sm:items-end inline-flex mb-[23px]">
                                    <div className="mr-[10px] ">
                                        <label className="inline-flex items-center border-1 border-[#666666] rounded">
                                            <input checked type="checkbox" className="form-checkbox block accent-gray-700 " />
                                        </label>
                                    </div>
                                    <p className="text-zinc-600 text-[15px] font-light">Earn passive income while you focus on your career</p>
                                </li>

                                <li className="justify-start items-start sm:items-end inline-flex mb-[23px]">
                                    <div className="mr-[10px] ">
                                        <label className="inline-flex items-center border-1 border-[#666666] rounded">
                                            <input checked type="checkbox" className="form-checkbox block accent-gray-700 " />
                                        </label>
                                    </div>
                                    <p className="text-zinc-600 text-[15px] font-light">Earn exponential income on multi levels with wallet referrals </p>
                                </li>

                                <li className="justify-start items-start sm:items-end inline-flex mb-[23px]">
                                    <div className="mr-[10px] ">
                                        <label className="inline-flex items-center border-1 border-[#666666] rounded">
                                            <input checked type="checkbox" className="form-checkbox block accent-gray-700 " />
                                        </label>
                                    </div>
                                    <p className="text-zinc-600 text-[15px] font-light">Facilitate transactions without hidden costs or charges</p>
                                </li>

                                <li className="justify-start items-start sm:items-end inline-flex mb-[23px]">
                                    <div className="mr-[10px] ">
                                        <label className="inline-flex items-center border-1 border-[#666666] rounded">
                                            <input checked type="checkbox" className="form-checkbox block accent-gray-700 " />
                                        </label>
                                    </div>
                                    <p className="text-zinc-600 text-[15px] font-light">Facilitate transactions without fear of dispensing errors</p>
                                </li>

                                <li className="justify-start items-start sm:items-end inline-flex mb-[23px]">
                                    <div className="mr-[10px] ">
                                        <label className="inline-flex items-center border-1 border-[#666666] rounded">
                                            <input checked type="checkbox" className="form-checkbox block accent-gray-700 " />
                                        </label>
                                    </div>
                                    <p className="text-zinc-600 text-[15px] font-light">Create a business for yourself without the stress of using a POS</p>
                                </li>
                                
                            </ul>
                        </div>
                        
                        <div>
                            <button className=" h-[67px] px-5 py-5 rounded border border-gray-700 text-gray-700 text-[16px] font-bold">
                                Click here to become an Agent
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='testimonials' className=' pt-[40px] pb-[128px] bg-[#16780D0D]'>
            <div className='custom-container '>
                <div className=" text-center mb-[90px]">
                    <h1 className="text-gray-700 text-[36px] font-bold">Testimonials</h1>
                    <div className="w-14 h-[3px] bg-black mx-auto mb-[0px]"></div>
                </div> 

                <Testimonials></Testimonials>
            </div>
        </section>

        <section id='faqs' className="pt-[132px] pb-[91px]">
            <div className=" custom-container flex flex-wrap gap-[47px]">
                <div className="w-full lg:w-[calc(40%-(47px/2))] ">
                    <div className=" text-center mb-[20px]">
                        <h1 className="text-gray-700 text-[36px] font-bold">Frequently Asked Questions (FAQ)</h1>
                        <div className="w-14 h-[3px] bg-black mx-auto mb-[15px]"></div>
                        <div className=" text-center text-zinc-600 text-[16px] font-normal mx-auto">We are here to guide you. Get answers to your most pressing questions about eNaira from this comprehensive FAQ section.</div>
                    </div>  
                    <img className=" w-[90%]" src="./assets/images/faqbox.png" alt="" />
                </div>
                <div className="lg:w-[calc(60%-(47px/2))] ">
                    <div onClick={() => {setFaqs({ ...faqs, 1: !faqs[1],  })}} className={ (faqs[1]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[1]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>Can I Pay With My eNaira And Cash At The Same Time For A Transaction?</p>
                            {
                                faqs[1]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[1] && <p className=" text-white text-[16px] font-light leading-tight">Yes. The eNaira and Cash are all variants of the legal tender. Either or both can be used to carry out transactions as the situation demands.</p>}
                    </div>

                    <div onClick={() => {setFaqs({ ...faqs, 2: !faqs[2],  })}} className={ (faqs[2]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[2]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>Is eNaira Wallet Safe?</p>
                            {
                                faqs[2]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[2] && <p className=" text-white text-[16px] font-light leading-tight">Yes. The eNaira system uses a two-factor authentication system in addition to cryptographic encryption to ensure the safety of customers’ wallets and the eNaira holding.</p>}
                    </div>

                    <div onClick={() => {setFaqs({ ...faqs, 3: !faqs[3],  })}} className={ (faqs[3]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[3]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>How secured is the eNaira?</p>
                            {
                                faqs[1]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[3] && <p className=" text-white text-[16px] font-light leading-tight">eNaira is safe and secured with cryptographic techniques against counterfeiting, cloning, and other forms of attack. The eNaira system uses a two-factor authentication system in addition to cryptographic encryption to ensure the safety of customers' wallets and the eNaira holding.</p>}
                    </div>

                    <div onClick={() => {setFaqs({ ...faqs, 4: !faqs[4],  })}} className={ (faqs[4]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[4]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>How Do I Resolve Failed Transactions Or Raise Enquiries On eNaira?</p>
                            {
                                faqs[4]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[4] && <p className=" text-white text-[16px] font-light leading-tight">Reach out to the customer support of the bank you selected when registering for eNaira through their available channels which include: phone, email, whatsapp, etc. Disputes will be resolved as soon as possible. If issues are not resolved within 48 hours, send an email to CBN’s eNaira Customer Support via helpdesk@enaira.gov.ng</p>}
                    </div>

                    <div onClick={() => {setFaqs({ ...faqs, 5: !faqs[5],  })}} className={ (faqs[5]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[5]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>Can I Pay Cash Into Any Bank And Instruct Them To Pay The Funds Into My eWallet?</p>
                            {
                                faqs[5]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[5] && <p className=" text-white text-[16px] font-light leading-tight">No. In order to move cash into your eNaira wallet, kindly go to your bank.</p>}
                    </div>

{/*                     <div onClick={() => {setFaqs({ ...faqs, 6: !faqs[6],  })}} className={ (faqs[6]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[6]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>How do I change my password?</p>
                            {
                                faqs[6]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[6] && <p className=" text-white text-[16px] font-light leading-tight">Lorem ipsum dolor sit amet consectetur. Vitae accumsan proin arcu sem duis urna fringilla egestas. Arcu amet viverra lobortis nulla.</p>}
                    </div>


                    <div onClick={() => {setFaqs({ ...faqs, 7: !faqs[7],  })}} className={ (faqs[7]? 'bg-[#3F4951] rounded-[10px]':'bg-white border border-[#959090] rounded-[6px]') + " cursor-pointer px-[17px] py-[20px] bg-[#3F4951]  mb-[27px]"}>
                        <div className=" flex justify-between items-center mb-[10px]">
                            <p className={ (faqs[7]? 'text-white':'text-[#3F4951]') + "  text-[28px] font-medium leading-[28px]"}>How do I change my picture?</p>
                            {
                                faqs[7]? (
                                    <svg className=" w-[24px] h-[24px] text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.25 17.25 6.75 6.75"></path>
                                        <path d="m17.25 6.75-10.5 10.5"></path>
                                    </svg>
                                ):(
                                    <svg className=" w-[24px] h-[24px] text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.25v13.5"></path>
                                        <path d="M18.75 12H5.25"></path>
                                    </svg>
                                )
                            }
                        </div>
                        { faqs[7] && <p className=" text-white text-[16px] font-light leading-tight">Lorem ipsum dolor sit amet consectetur. Vitae accumsan proin arcu sem duis urna fringilla egestas. Arcu amet viverra lobortis nulla.</p>}
                    </div>
 */}

                    

                    
                </div>
            </div>
        </section>
        
        <section className=" pt-[40px] pb-[121px] bg-[#3F4951]">
            <div className="custom-container">
                <div className=" text-center mb-[20px]">
                    <h1 className="text-white text-[36px] font-bold">Reviews</h1>
                    <div className="w-14 h-[3px] bg-white mx-auto mb-[63px]"></div>
                </div>  

                <Reviews></Reviews>
            </div>
        </section>

        <section className="bg-white py-[27px]">
            <div className=" custom-container">
                <div className=" w-max mx-auto items-center sm:flex gap-x-4">
                    <div className="">
                        <h1 className="text-gray-700 text-[36px] font-bold">In Partnership with </h1>
                    </div>
                    <div className=" ">
                        <img className="w-24 h-[109px] mx-auto" src="./assets/images/home-cbn-logo.png" alt=''/>
                    </div>
                </div>
            </div>
        </section>

        <section id='get-wallet' className=" pt-[40px] pb-[60px] bg-gradient-to-r from-green-800 via-green-800 to-green-800">
            <div className="custom-container ">
                <div className="relative">
                    <div className="w-auto lg:w-[547px]  text-center">
                        <div className="p-2 justify-start items-start gap-2 inline-flex">
                            <div className=" text-center text-white text-[36px] font-bold">Download the app or scan the QR code now to get started.</div>
                        </div>
                        <img className="block  h-[303px] mx-auto" src="./assets/images/qr-code.png" alt=''/>
                        <div className="p-2 justify-start items-start gap-2 inline-flex">
                            <div className="text-center text-white text-[16px] font-normal">Scan the QR code with your phone to download</div>
                        </div>
                    </div>
    
                    <div className="xl:w-[900.55px] mt-[40px] xl:mt-0 hidden md:inline-flex justify-center items-end gap-4 xl:absolute xl:-right-[40px] xl:top-0">
                        <img className="w-[309.25px] h-[189.84px] hidden lg:block" src="./assets/images/laptop.png" alt=''/>
                        <img className="w-[199.19px] h-[400px]" src="./assets/images/phone-2.png" alt=''/>
                        <img className="w-[242.74px] h-[495px]" src="./assets/images/phone-1.png" alt=''/>
                    </div>
                </div>
                
            </div>
        </section>

        <section className=" pt-[33px] pb-[14px] bg-[#16780D0D]">
            <div className="custom-container">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-[40px]">
                    <div>
                        <h1 className=" text-gray-700 text-[36px] font-bold leading-10 mb-[16px]">Subscribe to get special offers and latest updates from us</h1>
                        <div className=" text-black text-[20px] font-extralight mb-[49px]">We recommend you to subscribe to our newsletter, enter your e-mail to get our daily updates. </div>
                        <form onSubmit={(e)=> subscribe(e)}>
                            <div className="xl:w-[521px] mb-[11px]">
                                <input required value={formData.email} onChange={(e)=> setFormData({...formData,'email':e.target.value})} className="w-full h-12 pl-6 bg-transparent pt-3.5 pb-[15px] rounded placeholder:text-neutral-400 text-[16px] font-medium text-black border border-neutral-400 justify-start items-center inline-flex " placeholder="Your E-mail Address" type="text"/>
                            </div>
                            <div className="xl:w-[521px] mb-[11px]">
                                <input required value={formData.name} onChange={(e)=> setFormData({...formData,'name':e.target.value})} className="w-full h-12 pl-6 bg-transparent pt-3.5 pb-[15px] rounded placeholder:text-neutral-400 text-[16px] font-medium text-black border border-neutral-400 justify-start items-center inline-flex " placeholder="Your Name" type="text"/>
                            </div>
                            <div className="xl:w-[521px] mb-[41px]">
                                <input required value={formData.phone} onChange={(e)=> setFormData({...formData,'phone':e.target.value})} className="w-full h-12 pl-6 bg-transparent pt-3.5 pb-[15px] rounded placeholder:text-neutral-400 text-[16px] font-medium text-black border border-neutral-400 justify-start items-center inline-flex " placeholder="Your Phone Number" type="text"/>
                            </div>
                            {
                                formData.loading ? (
                                    <button type='button' className="xl:w-[521px] w-full h-12 py-[10px] bg-gray-400 rounded text-white text-[16px] font-bold">
                                        Loading...
                                    </button>
                                ): (
                                    <button type='submit' className="xl:w-[521px] w-full h-12 py-[10px] bg-green-800 rounded text-white text-[16px] font-bold">
                                        Subscribe
                                    </button>
                                )
                            }
                        </form>
                    </div>
                    <div className=" flex justify-center lg:justify-end items-center">
                        <div>
                            <img width="351" src="./assets//images/noto_open-mailbox-with-raised-flag.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer className=" pt-[55px] pb-[20px] bg-[#3F4951] relative">
            <div className=" custom-container flex flex-wrap xl:flex-nowrap gap-[40px] xl:gap-0">
                <div className="xl:mr-[51px] flex-shrink-0">
                    <div className=" mt-[30px] mb-[29px]">
                        <img src="./assets/images/footerLogo.png" alt=""/>
                    </div>

                    <div className="h-9 justify-center items-center gap-[22px] inline-flex">
                        <img src="./assets/images/bxl-facebook-circle.svg.svg" className="w-8 h-8  block"alt=''/>
                        <img src="./assets/images/bxl-instagram-alt.svg.svg" className="w-8 h-8  block"alt=''/>
                        <img src="./assets/images/bxl-twitter.svg.svg" className="w-8 h-8  block"alt=''/>
                        <img src="./assets/images/LinkedIn.png" className="w-8 h-8 block"alt=''/>
                    </div>
                </div>
                <div className=" xl:file:mr-[112px] flex-shrink-0">
                    <div className="  mb-[20px]">
                        <p className=" text-white text-[18px] font-bold leading-7">Quick as</p>
                        <div className="w-[42px] h-1 bg-green-800 rounded"></div>
                    </div>
                    <div className="">
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">Home</p>
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">About eNaira</p>
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">Benefit of eNaira</p>
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">Testimonials</p>
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">FAQs</p>
                    </div>
                </div>

                <div className=" xl:mr-[112px] flex-shrink-0">
                    <div className="  mb-[20px]">
                        <p className=" text-white text-[18px] font-bold leading-7">My Profile</p>
                        <div className="w-[42px] h-1 bg-green-800 rounded"></div>
                    </div>
                    <div className="">
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">Fund Wallet</p>
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">Transaction History</p>
                        <p className="text-white text-[14px] font-medium leading-snug mb-[20px]">Conversion Rate</p>
                    </div>
                </div>

                <div className=" xl:mr-[112px] flex-shrink-0">
                    <div className="  mb-[20px]">
                        <p className=" text-white text-[18px] font-bold leading-7">Newsletter</p>
                        <div className="w-[42px] h-1 bg-green-800 rounded"></div>
                    </div>
                    <div className="">
                        <p className="text-white text-[14px] font-medium leading-snug mb-[3px]">Sign up for the latest news and updates</p>
                        <div className=" w-[300px] h-[50px] py-[10px] px-[10px] flex bg-white rounded-[6px] items-center">
                            <input type="text" className="block w-full border-none outline-none text-black  placeholder:text-zinc-400 text-[12.12277889251709px] font-bold leading-none" placeholder="Your Email" />
                            <svg className="block w-[20px] h-[20px] flex-shrink-0 text-[#3F4951] -rotate-[30deg]" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m22.34 10.642-.007-.003-20.02-8.303a1.104 1.104 0 0 0-1.04.1 1.156 1.156 0 0 0-.523.966v5.31a1.125 1.125 0 0 0 .915 1.105l10.919 2.02a.187.187 0 0 1 0 .368L1.665 14.224a1.125 1.125 0 0 0-.915 1.104v5.31a1.105 1.105 0 0 0 .496.924 1.123 1.123 0 0 0 1.066.097l20.02-8.256.008-.004a1.5 1.5 0 0 0 0-2.757Z"></path>
                              </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" flex md:gap-x-[17px] justify-end lg:absolute lg:right-[20px] md:bottom-[20px] mt-10 md:mt-0">
                <div className=" p-2 justify-center items-center md:inline-flex">
                    <div className=" mr-1"><span className="text-white text-[12px] font-extrabold leading-relaxed">©2023  eNaira eNhanced Services Powered by </span></div>
                    <img className="w-[120.46px] h-[37.17px]" src="./assets/images/footer-Bullnet.png" alt=''/>
                </div>
                <div className="w-12 h-12 p-3 bg-white rounded-3xl justify-center items-center gap-2 inline-flex">
                    <svg className="w-6 h-6 relative text-[#3F4951]" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25 15.375 12 8.625l6.75 6.75"></path>
                    </svg>
                    
                </div>
            </div>
        </footer>
        <Toaster position="top-center" reverseOrder={false} 
                    toastOptions={{
                        className: '',
                        duration: 6000,
                        style: {
                            background: '#fff',
                            color: '#363636',
                            border: '1px solid green'
                        } 
                    }}/>
    </section>
  )
}
