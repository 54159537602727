import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay,Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "./SliderComp.css"

export default function SliderComp() {

  return (
    <>
        <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper"
            autoplay={{
                delay: 15000,
                disableOnInteraction: false,
              }}
        >
            <SwiperSlide>
                <div style={{backgroundImage: "url(./assets/images/slide23.jpeg)"}} class=" w-full h-[500px] shadow flex items-center bg-no-repeat bg-cover bg-left lg:bg-right">
                    <div class="custom-container flex flex-col justify-between">
                        <div class="w-auto lg:w-[50%]">
                            <div class="mb-[36px]">
                                <h1 class="text-[#3F4951] text-[25px] md:text-[40px]  md:leading-[45px] font-medium whitespace-pre-wrap mb-[20px]">Send money home from any foreign bank account to any Naira bank account for <span className='text-[#16780D] font-extrabold'>FREE</span></h1>
                                <div className='p-3 rounded-lg bg-[#16780D] md:w-[80%]'>
                                    <div className='flex items-center gap-x-2'>
                                        <img alt='' style={{width:'15px', height:'15px'}} className='block' src='/assets/images/check-mark.png' />
                                        <p className='text-white'>Fast Diaspora Micro-Remittances</p>
                                    </div>
                                    <div className='flex items-start gap-x-2'>
                                        <img alt='' style={{width:'15px', height:'15px'}} className='block mt-1' src='/assets/images/check-mark.png' />
                                        <p className='text-white'>No Third-Party Bank/Wallet Account Needed</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className=' flex flex-row gap-x-4 mb-[36px]'>
                                <a href='https://youtu.be/cstTaeD5JeM' class="h-[52px] pl-2 pr-2 bg-[#16780D] rounded flex items-center ">
                                    <div class="text-white text-[16px] font-bold mr-[10px]">Take a Virtual Tour </div>
                                    <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                                        <path d="M18.375 12H4.687"></path>
                                    </svg>
                                </a>
                                <a href='https://enaira-frontend.vercel.app/auth/login' class="h-[52px] pl-2 pr-2 bg-[#16780D] rounded flex items-center">
                                    <div class="text-white text-[16px] font-bold mr-[10px]">Send Money</div>
                                    <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                                        <path d="M18.375 12H4.687"></path>
                                    </svg>
                                </a>
                            </div>
                            
                        </div>
                        <div className='flex items-center justify-between'>
                            <img alt='' src='/assets/images/powered-by-bullnet-removebg-preview.png'/>
                            <img alt='' src='/assets/images/enaira-logo-removebg-preview.png'/>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div style={{backgroundImage: "url(./assets/images/heroSection.png)"}} class=" w-full h-[500px] shadow flex items-center ">
                    <div class="custom-container">
                        <div class="w-auto lg:w-[40%]">
                            <div class="mb-[36px]">
                                <h1 class="text-[#16780D] text-[25px] md:text-[40px] font-bold whitespace-pre-wrap">Reliable Referrals, Rewards, Funding & Agency Solutions for the eNaira CBDC </h1>
                                <p class=" text-neutral-500 text-[16px] font-light">Onboard, Transact or Start a business with eNaira from anywhere in the world online or offline</p>
                            </div>
                            <button class="h-[52px] pl-2 pr-2 bg-[#16780D] rounded flex items-center">
                                <a href='https://mywallet.enaira.gov.ng/registration/@bulletin.enquiries.networking.services.ltd.01' class="text-white text-[16px] font-bold mr-[10px]">Get eNaira Wallet</a>
                                <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                                    <path d="M18.375 12H4.687"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div style={{backgroundImage: "url(./assets/images/hero-section-1.png)"}} class=" w-full h-[500px] shadow flex items-center ">
                    <div class="custom-container">
                        <div class="w-auto lg:w-[60%] xl:w-[40%]">
                            <div class="mb-[36px]">
                                <h1 class="text-[#3F4951] text-[25px] md:text-[40px] font-bold whitespace-pre-wrap">Introducing <span className=' text-[#16780D]'>eNaira eNhanced Services </span> The Convenient Users & Agents Platform for the eNaira CBDC </h1>
                                <p class=" text-neutral-500 text-[16px] font-light">Fund your eNaira Wallet easily and make money easily as an eNaira Agent</p>
                            </div>
                            <button class="h-[52px] pl-2 pr-2 bg-[#16780D] rounded flex items-center">
                                <a href='https://mywallet.enaira.gov.ng/registration/@bulletin.enquiries.networking.services.ltd.01' class="text-white text-[16px] font-bold mr-[10px]">Get eNaira Wallet</a>
                                <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                                    <path d="M18.375 12H4.687"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            {/* <SwiperSlide>
                <div style={{backgroundImage: "url(./assets/images/header5.jpeg)"}} class=" w-full h-[500px] shadow flex items-center bg-no-repeat bg-cover">
                    <div class="custom-container">
                        <div class="w-auto lg:w-[40%]">
                            <div class="mb-[36px]">
                                <h1 class="text-[#3F4951] text-[40px] font-bold whitespace-pre-wrap">Introducing <span className=' text-[#16780D]'>eNaira eNhanced Services </span> The Convenient Users & Agents Platform for the eNaira CBDC </h1>
                                <p class=" text-neutral-500 text-[16px] font-light">Fund your eNaira Wallet easily and make money easily as an eNaira Agent</p>
                            </div>
                            <button class="h-[52px] pl-2 pr-2 bg-[#16780D] rounded flex items-center">
                                <div class="text-white text-[16px] font-bold mr-[10px]">Get eNaira Wallet</div>
                                <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                                    <path d="M18.375 12H4.687"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </SwiperSlide> */}
            
        </Swiper>
    </>
  ) 
}
