import React from "react";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";



// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";


import "./TestimonialStyle.css"


// import required modules
// import { EffectCoverflow, Pagination } from "swiper";

export default function Testimonials() {
  return (
    
    <div className="testimonials">
      {/* <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper2"

      >
        <SwiperSlide>
             <div className=' relative rounded-[14px] bg-red-900'>
                <div>
                  <iframe className="w-full rounded-[14px]" height="377.85" src="https://www.youtube.com/embed/iaQ-nBubleA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
             <div className=' relative rounded-[14px]'>
                <div>
                  <iframe className="w-full rounded-[14px]" height="377.85" src="https://www.youtube.com/embed/aLMxFTKFt9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
             <div className='relative rounded-[14px]'>
                  <div>
                    <iframe className="w-full rounded-[14px]" height="377.85" src="https://www.youtube.com/embed/XJ63R76K-mw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
              </div>
        </SwiperSlide>
        
      </Swiper> */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[40px]">
        <div className=' relative rounded-[14px] bg-red-900'>
            <div>
              <iframe className="w-full rounded-[14px]" height="300.85" src="https://www.youtube.com/embed/iaQ-nBubleA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div className=' relative rounded-[14px]'>
            <div>
              <iframe className="w-full rounded-[14px]" height="300.85" src="https://www.youtube.com/embed/aLMxFTKFt9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div className='relative rounded-[14px]'>
            <div>
              <iframe className="w-full rounded-[14px]" height="300.85" src="https://www.youtube.com/embed/XJ63R76K-mw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
      </div>
    </div>
  );
}
