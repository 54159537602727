import React from 'react';

const SubscibeModal = ({formData, setFormData, subscribe, showSubscribe,setShowSubscribe}) => {

    return (
        <>
            { showSubscribe === "Show" && <div className='absolute h-full top-0 left-0 w-full z-50'>
                <div onClick={()=>setShowSubscribe(null)} className='absolute h-full top-0 left-0 w-full bg-black bg-opacity-80'></div>
                <div className='relative top-[2000px] w-[80%] bg-white h-[auto] mx-auto p-[23px] rounded-[10px] lg:flex gap-[20px] xl:gap-x-[66px]'>
                    <button onClick={()=>setShowSubscribe(null)} className='absolute top-2 right-2'>
                        <svg width="32" height="32" fill="none" stroke="#7C7C7C" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
                            <path d="m15 9-6 6"></path>
                            <path d="m9 9 6 6"></path>
                        </svg>
                    </button>
                    <div className='bg-[rgba(22, 120, 13, 0.05)] w-[250px] mx-auto lg:w-[386px] flex-shrink-0 flex-grow-0'>
                        <img src="./assets/images/SubscribeImage.png" alt="" />
                    </div>
                    <div className=' w-full mt-[28px]'>
                        <div className=" mb-[34px] w-[80%]">
                            <img className='w-[139px]' src="./assets/images/Nav-logo.png" alt=""/>
                            <h1 className='mt-[12px] mb-[4px] text-gray-700 text-[32px] font-semibold leading-9'>
                                Subscribe to our Newsletter!
                            </h1>
                            <p class="text-neutral-400 text-base font-medium">Be the first to get exclusive offers and latest news</p>
                        </div>
                        <form onSubmit={(e)=> subscribe(e)}>
                            <div className="xl:w-[521px] mb-[11px]">
                                <input required onChange={(e)=> setFormData({...formData,'email':e.target.value})} className=" text-sm w-full h-10 pl-6 bg-transparent pt-3 pb-[15px] rounded placeholder:text-neutral-400 text-[16px] font-medium text-black border border-neutral-400 justify-start items-center inline-flex " placeholder="Your E-mail Address" type="email"/>
                            </div>
                            <div className="xl:w-[521px] mb-[11px]">
                                <input required onChange={(e)=> setFormData({...formData,'name':e.target.value})} className="text-sm w-full h-10 pl-6 bg-transparent pt-3 pb-[15px] rounded placeholder:text-neutral-400 text-[16px] font-medium text-black border border-neutral-400 justify-start items-center inline-flex " placeholder="Your Name" type="text"/>
                            </div>
                            <div className="xl:w-[521px] mb-[34px]">
                                <input required onChange={(e)=> setFormData({...formData,'phone':e.target.value})} className="text-sm w-full h-10 pl-6 bg-transparent pt-3 pb-[15px] rounded placeholder:text-neutral-400 text-[16px] font-medium text-black border border-neutral-400 justify-start items-center inline-flex " placeholder="Your Phone Number" type="number"/>
                            </div>
                            {
                                formData.loading ? (
                                    <button type='button' className="xl:w-[521px] w-full h-12 py-[10px] bg-gray-400 rounded text-white text-[16px] font-bold">
                                        Loading...
                                    </button>
                                ): (
                                    <button type='submit' className="xl:w-[521px] w-full h-12 py-[10px] bg-green-800 rounded text-white text-[16px] font-bold">
                                        Subscribe
                                    </button>
                                )
                            }
                            
                        </form>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default SubscibeModal;
